import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { useLocation } from '@reach/router'
import clsx from 'clsx'
import _ from 'lodash'

import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import Container from '@objects/container'
import Headline from '@objects/headline'
import Copy from '@objects/copy'
import Button from '@objects/button'
import PostStream from '@components/posts/stream'
import PostsFilter from '@components/posts/filter'

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    marginTop: theme.spacing(8),
  },
  pageSubline: {
    margin: theme.spacing(0, 0, 2, 0),
    color: theme.palette.red.main,
    ...theme.typography.h5,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  main: {
    [theme.breakpoints.up('lg')]: {
      paddingRight: theme.spacing(5),
    },
  },
  sidebar: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.up('lg')]: {
      marginTop: 0,
      paddingLeft: theme.spacing(8),
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: theme.spacing(10),
    },
  },
  box: {
    background: theme.palette.background.default,
    borderRadius: theme.spacing(4, 0, 0, 4),
    padding: theme.spacing(6, 6, 8, 6),
    marginBottom: theme.spacing(10),
    marginRight: theme.spacing(-4),
    [theme.breakpoints.up('md')]: {
      marginRight: 0,
    },
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(6, 8, 8, 8),
    },
    '& p': {
      ...theme.typography.teaser,
    },
  },
  hotline: {
    ...theme.typography.hotline,
    lineHeight: `${theme.spacing(7)}px`,
    marginTop: theme.spacing(4),
    display: 'block',
  },
  netiquette: {
    '& button, & a': {
      marginTop: theme.spacing(7),
    },
  },
  sideBarHeadline: {
    ...theme.typography.h4,
    marginBottom: theme.spacing(5),
  },
  headline: {
    ...theme.typography.h2,
    lineHeight: '38px',
    marginBottom: theme.spacing(13),
  },
}))

export const frontmatter = {
  pageBackground: 'grey',
  header: {
    cta: 'createDiscussionButton',
  },
  breadcrumbs: [
    {
      label: 'footer.dialog',
      link: 'dialog.path',
    },
  ],
}

function Forum() {
  const intl = useIntl()
  const location = useLocation()
  const classes = useStyles()

  const [topicIds, setTopicIds] = useState([])
  const toggleTopicIds = (id) => {
    const idArray = _.xor([...topicIds], [id])

    return idArray
  }

  useEffect(() => {
    let params = queryString.parse(location.search)
    const queryTopicIds = params['topicIds']
      ? params['topicIds'].split(',').map((id) => parseInt(id))
      : []
    setTopicIds(queryTopicIds)
  }, [location])

  return (
    <Container className={classes.root}
    ariaLabel="arialabel.allebeitraege"
    >
      <Grid container justify={'center'}>
        <Grid className={classes.main} item xs={12} md={10} lg={12}>
          <div className={classes.pageHeader}>
            <p className={classes.pageSubline}>
              {intl.formatMessage({ id: 'dialogue.subline' })}
            </p>
            <Headline className={classes.headline} level={1}>
              {intl.formatMessage({ id: 'dialogue.headline' })}
            </Headline>
          </div>
        </Grid>
        <Grid className={classes.main} item xs={12} md={10} lg={8}>
          <PostStream isWrapped={true} limit={20} useLastQuery baseLevel={1} />
        </Grid>
        <Grid className={classes.sidebar} item xs={12} md={10} lg={4}>
          <div className={clsx(classes.box, classes.topicFilter)}>
            <Headline level={2} className={classes.sideBarHeadline}>
              <FormattedMessage id={'dialogue.filter.headline'} />
            </Headline>
            <PostsFilter
              renderLink={(topic) => {
                const newTopicIds = toggleTopicIds(topic.id)
                const topicsQuery = newTopicIds.length
                  ? `?topicIds=${newTopicIds.join(',')}`
                  : ''
                return `${intl.formatMessage({
                  id: 'forum.path',
                })}${topicsQuery}`
              }}
              activeButton={(topic) => {
                return topicIds.indexOf(topic.id) > -1
              }}
              type={'sidebar'}
            />
          </div>

          <div className={clsx(classes.box)}>
            <Headline level={2} className={classes.sideBarHeadline}>
              <FormattedMessage id={'dialogue.sidebar.hotline.headline'} />
            </Headline>
            <Copy>
              <FormattedMessage
                id={'dialogue.sidebar.hotline.copy'}
                values={{
                  break: <br />,
                }}
              />
            </Copy>
            <a
              data-track-content
              data-tracking-id={`tel:${intl
                .formatMessage({
                  id: 'dialogue.sidebar.hotline.number',
                })
                .replace(/\s+/g, '')}`}
              className={classes.hotline}
              href={`tel:${intl
                .formatMessage({
                  id: 'dialogue.sidebar.hotline.number',
                })
                .replace(/\s+/g, '')}`}
            >
              <FormattedMessage id={'dialogue.sidebar.hotline.number'} />
            </a>
          </div>

          <div className={clsx(classes.box, classes.netiquette)}>
            <Headline level={2} className={classes.sideBarHeadline}>
              <FormattedMessage id={'dialogue.sidebar.netiquette.headline'} />
            </Headline>
            <Copy>
              <FormattedMessage id={'dialogue.sidebar.netiquette.copy'} />
            </Copy>
            <Button
              to={intl.formatMessage({ id: 'netiquette.path' })}
              color={'red'}
              type={'secondary'}
            >
              <FormattedMessage id={'button.netiquette'} />
            </Button>
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}

Forum.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
  modules: PropTypes.array,
  location: PropTypes.object,
}

export default Forum

// export const pageQuery = graphql`
