import React, { useEffect, useState, useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocation } from '@reach/router'
import queryString from 'query-string'
import _ from 'lodash'

import { makeStyles } from '@material-ui/core/styles'

import DialogContext from '@providers/dialogProvider'
import ToggleComponentContext from '@providers/toggleComponentProvider'
import api from '@api'

import PostCard from '@components/postCard'
import Select from '@objects/formFields/select'
import Button from '@objects/button'
import CreateContentButton from '@objects/button/createContentButton'
import PostSnackbar from '@components/postSnackbar'

const useStyles = makeStyles((theme) => ({
  resultMeta: {
    display: 'flex',
    flexDirection: 'column-reverse',
    '& > *': {
      marginBottom: theme.spacing(5),
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  resultActions: {
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  postCount: {
    fontWeight: 500,
  },
  postCountWrapped: {
    marginBottom: theme.spacing(8),
    fontSize: '18px',
    lineHeight: '23px',
  },
  orderSelect: {
    width: '100%',
    marginBottom: (props) =>
      props.isWrapped ? theme.spacing(8) : theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      minWidth: '308px',
    },
  },
  createArticle: {
    marginBottom: theme.spacing(15),
  },
  postRoot: {
    marginBottom: theme.spacing(10),
  },
  postRootLast: {
    marginBottom: theme.spacing(15),
  },
  postAnswerSingle: {
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(10),
    marginTop: theme.spacing(-3),
  },
  postAnswerDouble: {
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(15),
    marginTop: theme.spacing(-3),
  },
}))

const orderByOpts = (intl) => {
  return [
    {
      value: 'Newest',
      label: intl.formatMessage({ id: 'dialogue.order.Newest' }),
    },
    {
      value: 'MostPopular',
      label: intl.formatMessage({ id: 'dialogue.order.MostPopular' }),
    },
    {
      value: 'Oldest',
      label: intl.formatMessage({ id: 'dialogue.order.Oldest' }),
    },
    {
      value: 'MostControversial',
      label: intl.formatMessage({ id: 'dialogue.order.MostControversial' }),
    },
  ]
}

function PostStream({
  isWrapped,
  snackBarActive,
  limit,
  useLastQuery,
  baseLevel,
}) {
  const intl = useIntl()
  const classes = useStyles({ isWrapped })
  const location = useLocation()
  const { OverlayIsActive } = useContext(ToggleComponentContext)

  const postRequest = api()
  const { updatePostData, PostData, updateStreamQuery, StreamQuery } =
    useContext(DialogContext)
  const [posts, setPosts] = useState([])
  const [select, setSelect] = useState('Newest')
  const [nextSeek, setNextSeek] = useState(null)
  const [totalPosts, setTotalPosts] = useState(0)
  const [loading, setLoading] = useState(true)
  const [hasMorePosts, setHasMorePosts] = useState(true)
  const selectRef = useRef(null)

  const [latestId, setLatestId] = useState(0)
  const latestIdRef = useRef(latestId)

  let posY = 0

  const updateOpt = (opt, action) => {
    setSelect(opt.value)
    getPosts(getQuery({ sortOrder: opt.value, seek: null, limit: limit }))
    return opt
  }

  const getQuery = ({
    sortOrder = select,
    seek = nextSeek,
    querylimit = limit,
  }) => {
    let params = queryString.parse(location.search)
    const query = {}
    Object.keys(params).forEach((param) => {
      if (['topicIds'].includes(param)) {
        params[param] &&
          (query[param] = params[param].split(',').map((id) => parseInt(id)))
      }
    })
    query['sortOrder'] = sortOrder
    query['seek'] = seek
    query['limit'] = querylimit

    return query
  }

  const getPosts = (query, concat, reload) => {
    const top = selectRef.current?.offsetTop - 200
    setLoading(true)
    return postRequest
      .getPosts(query)
      .then((response) => {
        const postsList = concat
          ? posts.concat(response.data.result)
          : response.data.result

        setTotalPosts(response.data.totalNumberOfResults)
        setHasMorePosts(response.data.hasMoreResults)
        useLastQuery && updateStreamQuery(query)
        setNextSeek(response.data.nextSeek)
        setLoading(false)
        setPosts(postsList)
        if (reload || concat) {
          window.scrollTo({ top: concat ? posY : top })
        }
      })
      .catch((err) => {
        console.error('post query error', err)
        setLoading(false)
      })
  }

  const showMore = () => {
    posY = window.scrollY
    getPosts(getQuery({}), true)
  }

  const getPostsCallback = (
    query = { limit: limit, seek: null, sortOrder: 'Newest' },
    reload
  ) => {
    getPosts(getQuery(query), false, reload)
  }

  const snackBarGetUpdate = () => {
    if (latestIdRef.current > 0) {
      return postRequest
        .getPosts(getQuery({ limit: 5, seek: null, sortOrder: 'Newest' }))
        .then((response) => {
          const newPosts = response.data.result.filter(
            (post) => post.id > latestIdRef.current
          )
          return newPosts.length || 0
        })
        .catch((e) => console.error(e))
    } else {
      return 0
    }
  }

  const snackBarCallback = () => {
    getPostsCallback({ limit: limit, offset: 0, sortOrder: 'Newest' }, true)
  }

  const scrollToId = (idSelector) => {
    const element = document.getElementById(idSelector)
    if (element) {
      setTimeout(() => {
        window.scrollTo({
          behavior: element ? 'smooth' : 'auto',
          top: element
            ? element.getBoundingClientRect().top + window.pageYOffset - 32
            : 0,
        })
      }, 500)
    }
  }

  useEffect(() => {
    if (posts && posts.length) {
      const id = posts.reduce((highest, current) => {
        return current.id > highest.id ? current : highest
      })?.id
      setLatestId(id)
    }
  }, [posts])

  useEffect(() => {
    const params = queryString.parse(location.search)

    if (useLastQuery && StreamQuery && !params.topicIds) {
      setSelect(StreamQuery.sortOrder)
      getPosts({
        ...StreamQuery,
        topicIds: params.topicIds?.split(',') || [],
      }).then(() => {
        if (PostData.articleId) {
          scrollToId(`postcard-dialogue-${PostData.articleId}`)
        }
      })
    } else {
      getPosts(getQuery({ seek: null, limit: limit }))
    }
    updatePostData({ isSingle: false, isDeleted: false })
  }, [location.search])

  useEffect(() => {
    latestIdRef.current = latestId
  }, [latestId])

  function getAnswerBorder(answers) {
    if (answers > 0) {
      return answers > 1 ? 'double' : 'single'
    }
    return 'none'
  }

  return (
    <>
      <CreateContentButton
        className={classes.createArticle}
        color={'outlineGrey'}
        icon="Pen"
        fullwidth
      >
        <FormattedMessage id={'dialogue.createArticle'} />
      </CreateContentButton>
      <div className={classes.resultMeta}>
        <div
          className={clsx(classes.postCount, {
            [classes.postCountWrapped]: isWrapped,
          })}
        >
          {totalPosts ? (
            <>
              <FormattedMessage
                id={posts.length > 1 ? 'dialogue.posts' : 'dialogue.post'}
                values={{ totalPosts: totalPosts }}
              />
            </>
          ) : (
            ''
          )}
        </div>
        <div className={classes.orderSelect} ref={selectRef}>
          <Select
            settings={{
              value: orderByOpts(intl).filter((obj) => obj.value === select),
              defaultValue: orderByOpts(intl)[0],
              options: orderByOpts(intl),
              onChange: updateOpt,
            }}
          />
        </div>
      </div>
      {posts &&
        posts.map((post, index) => {
          const answerBorder = getAnswerBorder(
            post.numberOfUserAnswers + post.numberOfExpertAnswers
          )
          const questionComponent = (
            <PostCard
              key={_.uniqueId('forum-card-')}
              className={clsx({ [classes.postQuestion]: isWrapped })}
              postData={{
                likeCount: post.numberOfLikes,
                replyCount:
                  post.numberOfUserAnswers + post.numberOfExpertAnswers,
                userName: post.userName,
                userColor: post.userColor,
                timestamp: post.timestamp,
                isDeleted: post.isDeleted,
                deleteType: post.deleteType,
                topics: post.topics,
                title: post.title,
                text: post.text,
                id: post.id,
              }}
              isWrapped={isWrapped}
              answerBorder={answerBorder}
              link={
                !isWrapped
                  ? intl.formatMessage(
                      { id: 'forum.path.single' },
                      { postId: post.id }
                    )
                  : null
              }
              textLink={
                isWrapped
                  ? `${intl.formatMessage(
                      { id: 'forum.path.single' },
                      { postId: post.id }
                    )}?dialogueid=${post.id}`
                  : null
              }
              type={'dialogue'}
              baseLevel={baseLevel}
            />
          )
          const postAnswer = isWrapped && post.answers ? post.answers[0] : null

          const answerComponent = postAnswer ? (
            <PostCard
              key={_.uniqueId('answer-')}
              className={clsx({
                [classes.postAnswerSingle]: answerBorder === 'single',
                [classes.postAnswerDouble]: answerBorder === 'double',
              })}
              bar={'yellow'}
              background={'darkSmall'}
              postData={{
                likeCount: postAnswer.numberOfLikes,
                replyCount: postAnswer.comments.length,
                userName: postAnswer.userName,
                userColor: postAnswer.userColor,
                timestamp: postAnswer.timestamp,
                isDeleted: postAnswer.isDeleted,
                deleteType: postAnswer.deleteType,
                topics: postAnswer.topics,
                title: postAnswer.title,
                text: postAnswer.text,
                isExpert: postAnswer.isExpertAnswer,
                isEdited: postAnswer.isEdited,
                editReason: postAnswer.editReason,
                lastEditedTimestamp: postAnswer.lastEditedTimestamp,
                id: postAnswer.id,
                comments: postAnswer.comments,
              }}
              isWrapped={isWrapped}
              type={'dialogue-answer'}
              textLink={
                isWrapped
                  ? `${intl.formatMessage(
                      { id: 'forum.path.single' },
                      { postId: post.id }
                    )}?answerid=${postAnswer.id}`
                  : null
              }
              interactive={false}
              noMetaBar={true}
              baseLevel={baseLevel}
            />
          ) : null
          return isWrapped ? (
            <div
              className={clsx(
                {
                  [classes.postRoot]: posts.length !== index + 1,
                },
                {
                  [classes.postRootLast]: posts.length === index + 1,
                }
              )}
              key={_.uniqueId('post-')}
            >
              {questionComponent}
              {answerComponent}
            </div>
          ) : (
            questionComponent
          )
        })}
      {loading && (
        <PostCard
          key={_.uniqueId('forum-card-')}
          postData={{}}
          type={'dialogue'}
          loading={true}
          baseLevel={baseLevel}
        />
      )}
      <div className={clsx(classes.resultMeta, classes.resultActions)}>
        <CreateContentButton color={'yellow'} icon="Pen" type="secondary">
          <FormattedMessage id={'button.createArticle'} />
        </CreateContentButton>
        {hasMorePosts && (
          <Button
            color={'outline'}
            type="secondary"
            onClick={() => {
              showMore()
            }}
          >
            <FormattedMessage id={'button.more.posts'} />
          </Button>
        )}
      </div>
      {select === 'Newest' && snackBarActive && !OverlayIsActive && (
        <PostSnackbar
          interval={120}
          getUpdate={snackBarGetUpdate}
          actionCallback={snackBarCallback}
          actionLabel={intl.formatMessage({ id: 'snackbar.newpost.reload' })}
          countLabelId={'snackbar.newpost.count'}
        />
      )}
    </>
  )
}

PostStream.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  limit: PropTypes.number,
  snackBarActive: PropTypes.bool,
  useLastQuery: PropTypes.bool,
  isWrapped: PropTypes.bool,
}

PostStream.defaultProps = {
  snackBarActive: true,
  limit: 3,
  useLastQuery: false,
  isWrapped: false,
}

export default PostStream
